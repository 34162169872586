<template>
  <div class="container">
    <van-row>
      <van-col span="12" class="manager-plate" @click="getManager(1)">
        <img class="manager-icon" src="../../../assets/manager_icon1.png" alt />
        <img class="manager-back" src="../../../assets/manager_bac1.png" alt />
        <div class="manager-text">人员审批</div>
        <span></span>
      </van-col>
      <van-col span="12" class="manager-plate" @click="getManager(4)">
        <img class="manager-icon" src="../../../assets/manager_icon4.png" alt />
        <img class="manager-back" src="../../../assets/manager_bac4.png" alt />
        <div class="manager-text">查询跟进</div>
        <span></span>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="12" class="manager-plate" @click="getManager(2)">
        <img class="manager-icon" src="../../../assets/manager_icon2.png" alt />
        <img class="manager-back" src="../../../assets/manager_bac2.png" alt />
        <div class="manager-text">新增客户</div>
        <span></span>
      </van-col>
      <van-col span="12" class="manager-plate" @click="getManager(3)">
        <img class="manager-icon" src="../../../assets/manager_icon3.png" alt />
        <img class="manager-back" src="../../../assets/manager_bac3.png" alt />
        <div class="manager-text">我的客户</div>
        <span></span>
      </van-col>
    </van-row>
    <van-row>
      <van-col span="12" class="manager-plate" @click="getManager(5)">
        <img class="manager-icon" src="../../../assets/manager_icon5.png" alt />
        <img class="manager-back" src="../../../assets/manager_bac5.png" alt />
        <div class="manager-text">预约统计</div>
        <span></span>
      </van-col>
      <van-col span="12" class="manager-plate"></van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 点击页面跳转
    getManager(index) {
      if (index === 1) {
        this.$router.push({ path: "/personnel" });
      } else if (index === 2) {
        this.$router.push({ path: "/addCustomer" });
      } else if (index === 3) {
        this.$router.push({ path: "/myClient" });
      } else if (index === 4) {
        this.$router.push({ path: "/followUp" });
      } else if (index === 5) {
        this.$router.push({ path: "/appointStat" });
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  /deep/.van-row {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
  }
  .manager-plate {
    height: 120px;
    border-radius: 10px;
    margin: 10px;
    position: relative;
    .manager-icon {
      margin-top: 16px;
      margin-left: 16px;
    }
    .manager-back {
      position: absolute;
      top: 0;
      right: 0;
      height: 120px;
    }
    .manager-text {
      position: absolute;
      bottom: 15%;
      padding-left: 16px;
      color: #fff;
      font-size: 18px;
    }
    span {
      width: 10px;
      height: 10px;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
      position: absolute;
      right: 0px;
      position: absolute;
      bottom: 20%;
      right: 15%;
    }
  }
  .van-row:nth-child(1) .manager-plate:nth-child(1) {
    background: linear-gradient(-17deg, #fe6b5b 0%, #ffa67f 100%);
    box-shadow: 0px 5px 15px 0px rgba(254, 118, 98, 0.41);
  }
  .van-row:nth-child(1) .manager-plate:nth-child(2) {
    background: linear-gradient(-17deg, #ffa700 0%, #ffd400 100%);
    box-shadow: 0px 5px 15px 0px rgba(230, 155, 53, 0.41);
  }
  .van-row:nth-child(2) .manager-plate:nth-child(1) {
    background: linear-gradient(-17deg, #00d0d0 0%, #8bf7cc 100%);
    box-shadow: 0px 5px 15px 0px rgba(20, 213, 208, 0.41);
  }
  .van-row:nth-child(2) .manager-plate:nth-child(2) {
    background: linear-gradient(-17deg, #7f61ff 0%, #b589ff 100%);
    box-shadow: 0px 5px 15px 0px rgba(129, 98, 255, 0.41);
  }
  .van-row:nth-child(3) .manager-plate:nth-child(1) {
    background: linear-gradient(-17deg, #3480ff 0%, #00c4ff 100%);
    box-shadow: 0px 5px 15px 0px rgba(42, 141, 255, 0.41);
  }
}
</style>
